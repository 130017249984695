import Axios from 'axios'
// 登录
export function logon(data) {
  return Axios.post(window.Glod['private-login'] + '/api/srcLogin/userLogin', data)
}
// 获取密码策略（不需要binding ）  如点击忘记密码 重置密码此时还没有登录 reset.vue
export function getPasswordPolicy(data) {
  return Axios.post(window.Glod['private-login'] + '/api/mobileNoBinding/getPasswordPolicy', data)
}
// 发送邮件，用于重置密码 reset.vue
export function sendEmailForChangePassword(data) {
  return Axios.post(window.Glod['private-login'] + '/api/emailObject/sendEmailForChangePassword', data)
}

// 根据邮件里的验证码判断是否可以修改密码  reset.vue
export function isChangePasswordByEmail(data) {
  return Axios.post(window.Glod['private-login'] + '/api/emailObject/isChangePasswordByEmail', data)
}
// 修改密码  reset.vue
export function userPwModify(data) {
  return Axios.post(window.Glod['private-login'] + '/api/mobileNoBinding/userPwModify', data)
}
// 登录后修改密码 changePassWord
export function modifyUserPw(data, header) {
  return Axios.post(window.Glod['private-login'] + '/api/srcLogin/modifyPwWhenFirLog', data, {
    headers: header
  })
}

// 获取密码策略（需要binding ）  如changePassword 修改密码 此时已经登录
export function getPasswordPolicyWithBind(data, header) {
  return Axios.post(window.Glod['private-login'] + '/api/accessApi/login/getPasswordPolicy', data, {
    headers: header
  })
}

// 获取MFA二维码图片
export function getMFAQrCodeImg(data) {
  return Axios.post(window.Glod['private-login'] + '/api/srcLogin/getMFAQrCodeImg', data, {
    responseType: 'arraybuffer'
  })
}

// MFA验证码验证
export function mfaVerification(data) {
  return Axios.post(window.Glod['private-login'] + '/api/srcLogin/mfaVerification', data)
}



























// 向手机发送验证码，用于修改密码
export function sendMessageForChangePassword(data) {
  return Axios.post(window.Glod['private-login'] + '/api/mobileNoBinding/sendMessageForChangePassword', data)
}
// 根据手机号和验证码判断是否可以修改密码
export function isChangePasswordByVerificationCode(data) {
  return Axios.post(window.Glod['private-login'] + '/api/mobileNoBinding/isChangePasswordByVerificationCode', data)
}
// 向手机发送验证码，用于登录
export function sendMessageForLogin(data) {
  return Axios.post(window.Glod['private-login'] + '/api/mobileNoBinding/sendMessageForLogin', data)
}
